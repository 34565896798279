import { storage } from '@/tools';
import api from '@/common/api';
import {
  /* CONST */
  PG_TYPE,
  /* TYPE */
  TypePayment,
  TypeLanguage,
} from './interface';
import { Payment } from './Payment';

export default class GlobalPayment extends Payment {
  private _lang: TypeLanguage;

  get lang(): TypeLanguage {
    return this._lang;
  }

  constructor(paymentInfo: TypePayment, lang: TypeLanguage) {
    super(paymentInfo, PG_TYPE.PAYMENTWALL);
    this._lang = lang;
  }

  /**
   * @override
   */
  async fillPgForm(): Promise<void> {
    const lang = this._lang;
    const { mtIdx, titleForPG, totalPaidMoney, months } = this.paymentInfo;
    const form = document.querySelector('#PAYMENTWALL_PAY_INFO') as HTMLFormElement;
    if (form) {
      const mallReserved = `is_in_app=1&is_pc=0&month=${months}&lang=${lang}`;
      const oid = form.querySelector('.pg-oid') as HTMLInputElement;
      const amt = form.querySelector('.pg-amt') as HTMLInputElement;
      const etc = form.querySelector('.pg-mall-reserved') as HTMLInputElement;
      const planName = form.querySelector('.pg-goods-name') as HTMLInputElement;
      oid.value = mtIdx;
      amt.value = `${totalPaidMoney}`;
      etc.value = mallReserved;
      planName.value = titleForPG;
    }
  }

  /**
   * @override
   */
  async submitPgForm(): Promise<void> {
    try {
      const lang = this._lang;
      const { mtIdx, titleForPG, totalPaidMoney, months } = this.paymentInfo;
      const email = storage.persistent.get('ellaEmail') || '';
      const mallReserved = `is_in_app=1&is_pc=0&month=${months}&lang=${lang}`;

      const {
        result,
        data: { url },
      } = await api.post('/mvc/get_paymentwall_action_url', {
        email,
        mt_idx: mtIdx,
        amount: totalPaidMoney,
        plan_name: titleForPG,
        etc_info: mallReserved,
      });
      if (result !== 2000) throw new Error('결제 URL 가져오기 실패');
      window.location.href = url;
    } catch (error) {
      console.error(error);
    }
  }
}
