import { render } from "./Payment.vue?vue&type=template&id=65534794&scoped=true"
import script from "./Payment.vue?vue&type=script&lang=ts"
export * from "./Payment.vue?vue&type=script&lang=ts"

import "./Payment.vue?vue&type=style&index=0&id=65534794&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-65534794"
/* hot reload */
if (module.hot) {
  script.__hmrId = "65534794"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('65534794', script)) {
    api.reload('65534794', script)
  }
  
  module.hot.accept("./Payment.vue?vue&type=template&id=65534794&scoped=true", () => {
    api.rerender('65534794', render)
  })

}

script.__file = "src/page/payment/Payment.vue"

export default script