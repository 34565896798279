<template>
  <div class="loading">
    <div class="spinner" />
  </div>
</template>

<script lang="ts">
import { storage, native } from '@/tools';
import { PAY_METHOD } from "@/page/payment/modules/interface";
import { TypePayment } from '../order/modules/interface';
import PaymentKorean from './modules/PaymentKorean';
import PaymentKoreanNice from './modules/PaymentKoreanNice';
import PaymentGlobal from './modules/PaymentGlobal';

export default {
  name: 'Payment',
  async mounted() {
    const paymentInfo = storage.session.get('goodsForPayment') as unknown as TypePayment;
    if (paymentInfo) {
      // 결제 진행
      const { global, jiayouapp, lang } = this.$route.query;
      const isGlobal = !!Number(global);
      const isJiayouApp = !!Number(jiayouapp);
      // 토스
      // const payment = isGlobal ? new PaymentGlobal(paymentInfo, lang) : new PaymentKorean(paymentInfo, isJiayouApp);
      // Nicepay/토스 분기 : 카드결제만 Nicepay, 가상계좌는 토스
      let payment: PaymentGlobal | PaymentKoreanNice | PaymentKorean;
      const { payMethod } = paymentInfo;
      if (isGlobal) {
        payment = new PaymentGlobal(paymentInfo, lang)
      } else if (payMethod !== PAY_METHOD.VIRTUAL_ACCOUNT) {
        payment = new PaymentKoreanNice(paymentInfo, isJiayouApp);
      } else {
        payment = new PaymentKorean(paymentInfo, isJiayouApp);
      }

      await payment.init();
      await payment.loadPgForm();
      await payment.fillPgForm();
      payment.submitPgForm();
      storage.session.remove('goodsForPayment');
    } else {
      // 결제 완료
      const { mt_idx } = this.$route.query;
      storage.session.set('closeViewWithMtIdx', mt_idx);
      native.close();
    }
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: relative;
  min-height: 100vh;
  background: rgba(26, 26, 26, 0.3);
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -55px;
  margin-left: -24px;
  width: 48px;
  height: 48px;
  border-top: 4px solid #ededed;
  border-right: 4px solid #ededed;
  border-bottom: 4px solid #ededed;
  border-left: 4px solid #34bcff;
  border-radius: 50%;
  text-indent: -9999em;
  animation: spinner 0.8s infinite linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
