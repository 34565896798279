
import { storage, native } from '@/tools';
import { PAY_METHOD } from "@/page/payment/modules/interface";
import { TypePayment } from '../order/modules/interface';
import PaymentKorean from './modules/PaymentKorean';
import PaymentKoreanNice from './modules/PaymentKoreanNice';
import PaymentGlobal from './modules/PaymentGlobal';

export default {
  name: 'Payment',
  async mounted() {
    const paymentInfo = storage.session.get('goodsForPayment') as unknown as TypePayment;
    if (paymentInfo) {
      // 결제 진행
      const { global, jiayouapp, lang } = this.$route.query;
      const isGlobal = !!Number(global);
      const isJiayouApp = !!Number(jiayouapp);
      // 토스
      // const payment = isGlobal ? new PaymentGlobal(paymentInfo, lang) : new PaymentKorean(paymentInfo, isJiayouApp);
      // Nicepay/토스 분기 : 카드결제만 Nicepay, 가상계좌는 토스
      let payment: PaymentGlobal | PaymentKoreanNice | PaymentKorean;
      const { payMethod } = paymentInfo;
      if (isGlobal) {
        payment = new PaymentGlobal(paymentInfo, lang)
      } else if (payMethod !== PAY_METHOD.VIRTUAL_ACCOUNT) {
        payment = new PaymentKoreanNice(paymentInfo, isJiayouApp);
      } else {
        payment = new PaymentKorean(paymentInfo, isJiayouApp);
      }

      await payment.init();
      await payment.loadPgForm();
      await payment.fillPgForm();
      payment.submitPgForm();
      storage.session.remove('goodsForPayment');
    } else {
      // 결제 완료
      const { mt_idx } = this.$route.query;
      storage.session.set('closeViewWithMtIdx', mt_idx);
      native.close();
    }
  },
};
