import { storage, native, device, env } from '@/tools';
import date from '@/common/date';
import api from '@/common/api';
import {
  /* CONST */
  PG_TYPE,
  PAY_METHOD,
  /* TYPE */
  TypePayment,
} from './interface';
import { Payment } from './Payment';

export default class KoreanPaymentNice extends Payment {
  private _isJiayouApp: boolean;

  private _isInApp: boolean;

  private _isMobile: boolean;

  get isJiayouApp(): boolean {
    return this._isJiayouApp;
  }

  get isInApp(): boolean {
    return this._isInApp;
  }

  get isMobile(): boolean {
    return this._isMobile;
  }

  constructor(paymentInfo: TypePayment, isJiayouApp: boolean) {
    super(paymentInfo, PG_TYPE.NICEPAY);
    this._isJiayouApp = isJiayouApp;
    this._isInApp = !!env.isInApp();
    this._isMobile = !!device.isMobile();
  }

  /**
   * @override
   */
  init(): Promise<void> {
    return new Promise((resolve) => {
      const tag = document.createElement('script');
      const firstScriptTag = document.getElementsByTagName('script')[0];
      tag.id = 'nicepay-pc-script';
      tag.src = 'https://pg-web.nicepay.co.kr/v3/common/js/nicepay-pgweb.js';
      (firstScriptTag.parentNode as HTMLElement).insertBefore(tag, firstScriptTag);
      tag.addEventListener('load', () => resolve());
    });
  }

  /**
   * @override
   */
  async fillPgForm(): Promise<void> {
    const isPc = !this._isMobile;
    const isAppPay = this._isInApp;
    const isJiayouApp = this._isJiayouApp;
    const { mtIdx, titleForPG, totalPaidMoney, payMethod, months } = this.paymentInfo;

    const form = document.querySelector('#NICEPAY_MOBILE_FORM') as HTMLFormElement;
    const timestamp = form.querySelector('#EdiDate') as HTMLInputElement;
    const vBankExpiredDate = form.querySelector('#VbankExpDate') as HTMLInputElement;
    const charSet = form.querySelector('#CharSet') as HTMLInputElement;
    const goodsCl = form.querySelector('#GoodsCl') as HTMLInputElement;
    const goodsCnt = form.querySelector('#GoodsCnt') as HTMLInputElement;
    const buyerTel = form.querySelector('#BuyerTel') as HTMLInputElement;
    const buyerAddr = form.querySelector('#BuyerAddr') as HTMLInputElement;
    const encryptData = form.querySelector('#EncryptData') as HTMLInputElement;
    const payMethodNice = form.querySelector('#PayMethod') as HTMLInputElement;
    const returnUrl = form.querySelector('#ReturnURL') as HTMLInputElement;
    const wapUrl = form.querySelector('#WapUrl') as HTMLInputElement;
    const ispCancelUrl = form.querySelector('#IspCancelUrl') as HTMLInputElement;
    const mOid = form.querySelector('#Moid') as HTMLInputElement;
    const amt = form.querySelector('#Amt') as HTMLInputElement;
    const mallReserved = form.querySelector('#MallReserved') as HTMLInputElement;
    const goodsName = form.querySelector('#GoodsName') as HTMLInputElement;
    const buyerName = form.querySelector('#BuyerName') as HTMLInputElement;
    const buyerEmail = form.querySelector('#BuyerEmail') as HTMLInputElement;

    vBankExpiredDate.value = Date.prototype.noSeparatorFormatYYYYMMDDHHmmss.call(date.addDays(new Date(), 1)).slice(0, 8);

    charSet.value = 'utf-8';
    goodsCl.value = '1';
    goodsCnt.value = '1';
    buyerTel.value = '025458811';
    buyerAddr.value = '';
    returnUrl.value = this.getApiUrl('mvc', 'charge_money_nicepay');

    mOid.value = mtIdx;
    amt.value = `${totalPaidMoney}`;
    goodsName.value = titleForPG;

    // is_in_app 값이 없으면 API에서 PC로 인식함
    mallReserved.value = `is_in_app=${isAppPay ? 1 : 0}&is_pc=${isPc ? 1 : 0}&month=${months}&lang=kr${
      payMethod === PAY_METHOD.VIRTUAL_ACCOUNT
        ? `&expireDt=${Date.prototype.formatYYYYMMDDHHmmss.call(date.addDays(new Date(), 1))}`
        : ''
    }`;

    const email = storage.persistent.get('ellaEmail') || '';
    const emailTemp = email.replace(/\s/gi, '');
    buyerName.value = emailTemp || 'nobody';
    buyerEmail.value = email.replace(/\s/gi, '');

    if (isAppPay && !!native.isIOS()) {
      const iosPayUrlPrefix = isJiayouApp ? 'tutoringcn' : 'tutoring';
      wapUrl.value = `${iosPayUrlPrefix}nicepay://`;
      ispCancelUrl.value = `${iosPayUrlPrefix}nicepay://`;
    } else {
      wapUrl.value = '';
      ispCancelUrl.value = '';
    }

    timestamp.value = Date.prototype.noSeparatorFormatYYYYMMDDHHmmss.call(new Date());
    const pgHashData = await this.createPgHashData(timestamp.value);
    encryptData.value = pgHashData.replace(/\s/gi, '');

    if (payMethod === PAY_METHOD.CARD) {
      payMethodNice.value = 'CARD';
    } else if (payMethod === PAY_METHOD.VIRTUAL_ACCOUNT) {
      payMethodNice.value = 'VBANK';
    }
  }

  /**
   * @override
   */
  submitPgForm(): void {
    const form = document.querySelector('#NICEPAY_MOBILE_FORM') as HTMLFormElement;
    form.target = '_self';
    form.action = 'https://web.nicepay.co.kr/smart/paySmart.jsp';
    form.submit();
  }

  async createPgHashData(timestamp: string): Promise<string> {
    try {
      const { mtIdx, totalPaidMoney } = this._paymentInfo;
      const hashData = await api.post('/mvc/make_pg_hash_data', {
        pg: PG_TYPE.NICEPAY,
        timestamp,
        amt: `${totalPaidMoney}`,
        oid: mtIdx,
      });
      return hashData as unknown as string;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  getApiUrl(type: string, action: string): string {
    const envId = localStorage.getItem(type === 'mvc'  || type === "_admin" ? 'mvcEnvId' : 'apiEnvId');
    const prefix = envId ? `${envId}-` : '';
    const apiServerUrl = `https://${prefix}api.tutoring.co.kr`;
    return `${apiServerUrl}/${type}/${action}`;
  }
}
